.music-player {
    width: 300px;
    margin: 20px;
    padding: 10px;
    border: 2px solid #000;
    background: #444;
    color: #fff;
    font-family: 'Arial', sans-serif;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .winamp-skin {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .display {
    width: 100%;
    background: #222;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #000;
  }
  
  .display .title {
    font-size: 14px;
    font-weight: bold;
  }
  
  .display .artist {
    font-size: 12px;
    color: #ccc;
  }
  
  .controls {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .controls button {
    background: #333;
    border: 1px solid #000;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .controls button:hover {
    background: #555;
  }
  