/* Cursor Rainbow */
body {
  cursor: url('/public/dogecursor.png'), auto;
}

/* Background Images and GIFs */
body::before, body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind all content */
  background-size: cover;
  background-position: center;
  opacity: 0.5; /* Adjust as needed */
  pointer-events: none; /* Prevent interaction with background */
  transition: opacity 0.5s ease-in-out;
}

body::before {
  background-image: url('./images/1.jpg'); /* Image for Doge theme */
}

body::after {
  background-image: url('./images/11.webp'); /* Additional image if needed */
  opacity: 0.3; /* Less opacity to blend with the first image */
}

@keyframes gifBackground {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

body {
  animation: gifBackground 10s infinite;
}

/* Old Windows 98 Style */
.rmc-player-container {
  background-color: #c0c0c0 !important; /* Grey background */
  border: 2px solid #000 !important; /* Black border */
  box-shadow: none !important; /* Remove shadow */
  font-family: 'MS Sans Serif', Arial, sans-serif !important; /* Windows 98 font */
}

.rmc-player-main {
  background-color: #c0c0c0 !important;
}

.rmc-player-panel {
  background-color: #c0c0c0 !important;
  border-top: 1px solid #000 !important;
}

.rmc-panel-list {
  background-color: #c0c0c0 !important;
  border: none !important;
}

.rmc-btn, .rmc-switch {
  background-color: #c0c0c0 !important;
  border: 1px solid #000 !important;
  color: #000 !important;
}

.rmc-btn:hover, .rmc-switch:hover {
  background-color: #a0a0a0 !important; /* Darker grey on hover */
}

/* Background Containers */
#background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind all content */
  pointer-events: none; /* Prevent interaction with background */
}

.background-item {
  position: absolute;
  max-width: 200px;
  max-height: 200px;
  opacity: 0.8; /* Adjust opacity as needed */
  animation: float 10s infinite ease-in-out alternate;
}

/* Positions for background items */
#item1 { top: 10%; left: 20%; }
#item2 { top: 50%; left: 80%; }
#item3 { top: 80%; left: 40%; }
#item4 { top: 30%; left: 10%; }
#item5 { top: 60%; left: 70%; }

@keyframes float {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}

#item1 { animation-duration: 12s; }
#item2 { animation-duration: 14s; }
#item3 { animation-duration: 10s; }
#item4 { animation-duration: 16s; }
#item5 { animation-duration: 18s; }

/* Global Styles and Colors */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Aachen Std Medium', Arial, sans-serif;
  background-color: #fff0f5; /* Light pink background */
  color: #4A3B3B; /* Dark brown text */
  scroll-behavior: smooth;
}

.App-header {
  padding: 20px;
  background-color: #e6b47b; /* Pink header */
  color: #4A3B3B;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease;
}

.App-footer {
  background-color: #e6b47b;
  color: #4A3B3B;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 150px;
  height: 150px;
  transition: transform 0.3s ease;
}

.logo img:hover {
  transform: rotate(360deg);
}

h1 {
  font-size: 60px;
  margin: 0;
  font-family: 'Borel-Regular', sans-serif;
  transition: color 0.3s ease;
}

h1:hover {
  color: #e6b47b;
}

main {
  text-align: center;
  padding: 40px 20px;
  transition: padding 0.5s;
}

.title-container {
  margin-top: 50px;
  margin-bottom: 20px;
  transition: margin 0.5s;
}

.image-container img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
}

.image-container img:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
}

.animated-title {
  font-size: 70px;
  color: #4A3B3B;
  font-weight: bold;
  font-family: 'LubalinGraphStd-Demi';
  position: relative;
  animation: zoomInOut 3s infinite;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.cat-in-a-pan-container {
  background-color: #e6b47b;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
  max-width: 600px;
  margin: auto;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.cat-in-a-pan-container:hover {
  background-color: #e6b47b;
}

.just-a-cat-in-a-pan {
  font-size: 50px;
  color: #4A3B3B;
  margin: 0;
  text-align: center;
}

.music-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

/* Welcome Modal Styles */
.welcome-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.welcome-content {
  background-color: #e6b47b;
  padding: 40px;
  border-radius: 12px;
  text-align: center;
  color: #4A3B3B;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.welcome-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.welcome-button {
  background-color: #e7aa70;
  color: #4A3B3B;
  border: none;
  border-radius: 25px;
  padding: 10px 30px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px rgba(0, 0, 0, 0.1);
}

.welcome-button:hover {
  background-color: #e7aa70;
}

.buy-button a {
  background-color: #e6b47b;
  border: 2px solid #4A3B3B;
  border-radius: 25px;
  color: #4A3B3B;
  padding: 20px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 20px;
}

.buy-button a:hover {
  transform: scale(0.95);
}

.buy-button a:active {
  transform: translateY(3px);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.copy-text {
  font-size: 35px;
  color: #4A3B3B;
  margin-top: 20px;
  font-family: 'BricolageGrotesque';
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.social-icon {
  font-size: 24px;
  margin: 0 10px;
  padding: 10px;
  width: 90px;
  border-radius: 50%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

/* HEART MOUSE */
.decoration {
  position: fixed;
  width: 40px;
  height: 40px;
  background-size: cover;
  pointer-events: none;
  animation: float 1s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes float {
  0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
  }
  100% {
      opacity: 0;
      transform: translate(-50%, -200%) scale(1.5);
  }
}
.social-icon.twitter:hover, .social-icon.dexscreener:hover {
  background-color: #e7aa70;
  color: #e7aa70;
  transform: scale(1.1);
  border-radius: 50%;
}

.social-icon.telegram:hover {
  background-color: #e7aa70;
  color: #e7aa70;
  transform: scale(1.1);
  border-radius: 50%;
}

.social-icon.dextools:hover {
  background-color: #e7aa70;
  color: #e7aa70;
  transform: scale(1.1);
  border-radius: 50%;
}
.social-icon img {
  width: auto;
  height: 100%;
  border-radius: 50%;
}

.image-container {
  margin: auto;
  max-width: 600px;
  border-radius: 20px;
  transition: transform 0.5s ease-in-out;
}
.image-container:hover {
  transform: scale(1.05);
}

.resource-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 20px;
  background-color: #e6b47b;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.resource-item:hover {
  transform: scale(1.05);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
}

.resource-item img {
  max-width: 100px;
  border-radius: 20px;
}

.custom-section {
  text-align: center;
  margin-top: 100px;
}

.custom-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #4A3B3B;
}

.navigate-button,
.download-button {
  background-color: #4A3B3B;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.navigate-button:hover,
.download-button:hover {
  background-color: #3b3131;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.2);
}

/* GALLERY */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.gallery-item {
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.2);
}

.gallery-image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.1);
}

.custom-section {
  text-align: center;
  margin-top: 100px;
  padding: 60px 20px;
}

.custom-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #4A3B3B;
}

.custom-section .navigate-button {
  background-color: #4A3B3B;
  color: #FFFFFF;
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.custom-section .navigate-button:hover {
  background-color: #3b3131;
}

.resource-button {
  background-color: #4A3B3B;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.resource-button a:hover {
  transform: scale(0.95);
}

.marquee {
  width: 100%;
  background-color: #ffd4e4;
  color: #4A3B3B;
  font-size: 24px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
}

.marquee span {
  display: block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.youtube-section {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}
.youtube-video {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tokenomics {
  text-align: center;
  padding: 60px 20px;
  color: #4A3B3B;
}

.tokenomics h2 {
  font-size: 48px;
  margin-bottom: 40px;
  letter-spacing: 2px;
}

.tokenomics-item h3 {
  font-size: 30px;
}

.tokenomics-item p {
  font-size: 24px;
}

.tokenomics-image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 5px solid #4A3B3B;
  display: block;
  margin: 0 auto 20px;
}

.tokenomics-details {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 40px;
}

.tokenomics-item {
  text-align: center;
  flex: 1;
  margin: 10px;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    padding: 10px;
  }

  .logo img {
    width: 100px;
    height: 100px;
  }

  h1 {
    font-size: 40px;
  }

  .copy-text {
    display: none;
  }

  .title-container {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .buy-button a {
    padding: 15px 30px;
    font-size: 24px;
    border-radius: 20px;
  }

  .animated-title {
    font-size: 48px;
  }

  .image-container {
    margin-top: 20px;
    max-width: 100%;
  }


  .custom-message {
    padding: 20px;
    font-size: 18px;
  }

  .youtube-section {
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }

  .youtube-section iframe {
    width: 100%;
    height: 45vw;
    max-height: 450px;
  }

  .tokenomics {
    padding: 20px 0;
  }

  .tokenomics-item {
    margin-bottom: 20px;
  }

  .tokenomics-image {
    width: 120px;
    height: 120px;
    border: 3px solid #4A3B3B;
    margin: 0 auto 10px;
  }

  .tokenomics h2 {
    font-size: 32px;
  }

  .tokenomics-item h3,
  .tokenomics-item p {
    font-size: 20px;
  }

  .tokenomics-details {
    flex-direction: column;
    align-items: center;
  }

  .custom-section .navigate-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  .download-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .social-icons {
    flex-direction: column;
    align-items: center;
  }

  .social-icon {
    width: 60px;
    height: 60px;
    margin: 5px;
  }

  .music-container {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .background-item {
    max-width: 100px;
    max-height: 100px;
  }
}
